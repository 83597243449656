<script>
import { mapGetters } from 'vuex'

export default defineNuxtComponent({
  name: 'Navbar',
  components: {},
  layout: 'plain',
  methods: {
    async logout() {
      await this.$auth.logout()
      this.$router.push('/login')
    },
    chooseWasteCompanyBranch() {
      // SET wasteCompanyBranchId to VUEX global && use it
      // This should be possible only for "admin" & "spravce" - all of it.

      let culture = this.getBranch(this.wasteCompanyBranchId).wasteCompanyCulture ?? 'cs'
      if (culture === '') culture = 'cs'

      this.$store.dispatch('global/setCountryCulture', culture)

      let countryCode = null
      if (culture === 'cs') countryCode = 'CZ'
      else if (culture === 'sk') countryCode = 'SK'

      if (countryCode) {
        this.$store.dispatch('global/setCountryCode', countryCode)
      }

      this.$store.dispatch('global/setWasteCompanyBranchId', this.wasteCompanyBranchId)
      // fetch logo of the chosen branch
      this.$store.dispatch('waste_companies/fetchLogo', this.getBranch(this.wasteCompanyBranchId).wasteCompanyId)
      this.$global.getTranslator().setLocale(culture)
    },
    transformWasteCompanyBranchOption(option) {
      return {
        value: option.wasteCompanyBranchId,
        label: `${option.wasteCompanyName} - ${option.wasteCompanyBranchName}`,
        logoUrl: option.wasteCompanyLogoUrl ? this.$global.prefixImageUrl(option.wasteCompanyLogoUrl) : null,
      }
    },
    authUserName(fullName = false) {
      // for 'social' auth method
      if (this.$auth.user && this.$auth.user.name) return this.$auth.user.name
      // for 'oidc' auth method
      let userInfo = this.getUserInfo
      if (userInfo) return fullName ? userInfo.fullName : userInfo.lastName || userInfo.firstName
    },
    async redirectToUserProfile() {
      await this.$router.push('/profile')
    },
  },
  computed: {
    ...mapGetters({
      getWasteCompanyBranchOptions: 'users/getBranches',
      getBranch: 'users/getBranch',
      getWasteCompanyBranchId: 'global/getWasteCompanyBranchId',
      getWasteCompanyLogo: 'waste_companies/getLogo',
      getUserInfo: 'users/getUserInfo',
    }),
    version() {
      return useRuntimeConfig().public.APP_VERSION
    },
    companyLogo() {
      if (this.getWasteCompanyLogo) return this.getWasteCompanyLogo
      if (
        this.getBranch(this.getWasteCompanyBranchId) &&
        this.getBranch(this.getWasteCompanyBranchId).wasteCompanyLogoUrl
      ) {
        return this.$global.prefixImageUrl(this.getBranch(this.getWasteCompanyBranchId).wasteCompanyLogoUrl)
      }
      return null
    },
    companyBranchName() {
      let wasteCompanyBranch = this.getBranch(this.getWasteCompanyBranchId)
      return wasteCompanyBranch ? wasteCompanyBranch.wasteCompanyBranchName : ''
    },
    isWasteCompaniesActive() {
      return (
        (this.$route.name && this.$route.name?.startsWith('waste-companies')) ||
        this.$route.name?.startsWith('administration-users') ||
        this.$route.name?.startsWith('administration-devices') ||
        this.$route.name?.startsWith('administration-external-services')
      )
    },
    showAdministrationSubmenu() {
      return (
        (this.$project.isUserAllowed('r', 'AllWasteCompanyUsers') || this.$project.isUserAllowed('r', 'AllDevices')) &&
        this.$route.name &&
        (this.$route.name?.startsWith('waste-companies') ||
          this.$route.name?.startsWith('administration-users') ||
          this.$route.name?.startsWith('administration-devices') ||
          this.$route.name?.startsWith('administration-external-services'))
      )
    },
    getResourcesLink() {
      let link = ''
      if (this.$project.isUserAllowed('r', 'Car')) link = '/waste-company-resources/cars'
      else if (this.$project.isUserAllowed('r', 'Worker')) link = '/waste-company-resources/workers'
      else if (this.$project.isUserAllowed('r', 'Device')) link = '/waste-company-resources/devices'

      return link
    },
  },
  data() {
    return {
      wasteCompanyBranchId: null,
      wasteCompanyBranch: {},
      wasteCompanyBranchLogo: null,
      config: {
        waste_company_branch: {
          fetch: {
            url: 'users/fetchAllCompanyBranches',
          },
          get: {
            url: 'users/getBranches',
          },
        },
      },
    }
  },
})
</script>

<template>
  <div>
    <header class="o-header sticky-top" role="banner">
      <nav class="navbar navbar-expand-xl navbar-light bg-white">
        <div class="container-xxxl">
          <a href="/" class="navbar-brand">
            <img src="~/assets/images/econit-logo.svg" alt="ECONIT" />
          </a>
          <button
            class="navbar-toggler bg-primary shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon" />
          </button>
          <div id="navbarCollapse" class="collapse navbar-collapse">
            <ul class="nav navbar-nav me-auto">
              <li v-if="$project.isUserAllowed('r', 'dashboard')" class="nav-item">
                <nuxt-link
                  dusk="navbar-dashboard"
                  class="nav-link"
                  to="/dashboard"
                  :class="{
                    active: $route.name && $route.name?.startsWith('dashboard'),
                  }"
                >
                  <svg class="icon nav-link__icon" viewBox="0 0 20 20" width="20px" height="20px" aria-hidden="true">
                    <path
                      d="M6.692,0H2.393C1.074,0,0,1.072,0,2.393v4.299c0,1.32,1.074,2.395,2.393,2.395h4.299c1.32,0,2.394-1.074,2.394-2.395V2.393C9.085,1.072,8.012,0,6.692,0z M7.165,6.691c0,0.262-0.212,0.473-0.473,0.473H2.393c-0.261,0-0.473-0.211-0.473-0.473V2.393c0-0.26,0.212-0.473,0.473-0.473h4.299c0.261,0,0.473,0.213,0.473,0.473V6.691z M17.577,0h-4.241c-1.335,0-2.422,1.086-2.422,2.422v4.24c0,1.336,1.087,2.424,2.422,2.424h4.241C18.913,9.086,20,7.998,20,6.662v-4.24C20,1.086,18.913,0,17.577,0z M18.08,6.662c0,0.277-0.226,0.502-0.502,0.502h-4.241c-0.277,0-0.502-0.225-0.502-0.502v-4.24c0-0.277,0.225-0.502,0.502-0.502h4.241c0.277,0,0.502,0.225,0.502,0.502V6.662z M6.692,10.914H2.393C1.074,10.914,0,11.988,0,13.307v4.299C0,18.926,1.074,20,2.393,20h4.299c1.32,0,2.394-1.074,2.394-2.395v-4.299C9.085,11.988,8.012,10.914,6.692,10.914z M7.165,17.605c0,0.262-0.212,0.475-0.473,0.475H2.393c-0.261,0-0.473-0.213-0.473-0.475v-4.299c0-0.26,0.212-0.473,0.473-0.473h4.299c0.261,0,0.473,0.213,0.473,0.473V17.605z M17.577,10.914h-4.241c-1.335,0-2.422,1.086-2.422,2.422v4.24c0,1.336,1.087,2.424,2.422,2.424h4.241C18.913,20,20,18.912,20,17.576v-4.24C20,12,18.913,10.914,17.577,10.914z M18.08,17.576c0,0.277-0.226,0.504-0.502,0.504h-4.241c-0.277,0-0.502-0.227-0.502-0.504v-4.24c0-0.277,0.225-0.502,0.502-0.502h4.241c0.277,0,0.502,0.225,0.502,0.502V17.576z"
                    />
                  </svg>
                  {{ $_('components/navbar.menu_items.dashboard', 'Přehled') }}
                </nuxt-link>
              </li>

              <li v-if="$project.isUserAllowed('r', 'WasteCollectionRoute')" class="nav-item">
                <nuxt-link
                  dusk="navbar-waste-collection-routes"
                  class="nav-link"
                  to="/waste-collection-routes/regular"
                  :class="{
                    active: $route.name && $route.name?.startsWith('waste-collection-routes'),
                  }"
                >
                  <svg class="icon nav-link__icon" viewBox="0 0 20 20" width="20px" height="20px" aria-hidden="true">
                    <path
                      d="M4.991,8.734l2.589,1.653L6.36,4.764L0.79,6.036L3.452,7.75c-0.036,0.061-2.357,3.874-3.023,4.974c-0.544,0.899-0.57,1.809-0.076,2.703c0.505,0.914,1.303,1.381,2.372,1.388c0.368,0.003,3.482,0,3.482,0v-1.818c0,0-3.087-0.002-3.432,0.002l-0.017,0.001c-0.354,0-0.646-0.162-0.799-0.444C1.803,14.267,1.819,13.901,2,13.623C2.53,12.807,4.991,8.734,4.991,8.734z M19.609,12.771c-0.167-0.278-1.558-2.502-1.558-2.502l-1.542,0.964c0,0,1.165,1.877,1.495,2.401c0.213,0.338,0.227,0.66,0.042,0.984c-0.179,0.313-0.448,0.459-0.852,0.459c-0.992-0.002-5.824,0-5.824,0v-3.02l-3.912,3.945l3.925,3.856v-2.969c0,0,5.249,0.009,5.933,0c0.981-0.012,1.853-0.53,2.332-1.386C20.131,14.643,20.116,13.621,19.609,12.771z M7.808,4.353c0,0,1.012-1.576,1.243-1.933C9.238,2.13,9.508,1.97,9.812,1.97c0.089,0,0.181,0.014,0.273,0.041c0.264,0.079,0.428,0.284,0.553,0.474c0.429,0.651,2.98,4.53,3.046,4.631l-2.461,1.691l5.59,1.081l1.04-5.618l-2.66,1.813c0,0-2.559-3.9-3.176-4.795c-0.497-0.719-1.32-1.148-2.203-1.148c-0.863,0-1.645,0.395-2.145,1.084c-0.325,0.447-1.387,2.15-1.387,2.15L7.808,4.353z"
                    />
                  </svg>
                  {{ $_('components/navbar.menu_items.waste_collection_routes', 'Svozové trasy') }}
                </nuxt-link>
              </li>

              <li
                v-if="
                  $project.isUserAllowed('r', 'Car') ||
                  $project.isUserAllowed('r', 'Worker') ||
                  $project.isUserAllowed('r', 'Device')
                "
                class="nav-item"
              >
                <nuxt-link
                  dusk="navbar-waste-company-resources"
                  class="nav-link"
                  :to="getResourcesLink"
                  :class="{
                    active: $route.name && $route.name?.startsWith('waste-company-resources'),
                  }"
                >
                  <svg class="icon nav-link__icon" viewBox="0 0 20 20" width="20px" height="20px" aria-hidden="true">
                    <path
                      d="M19.926,9.433l-0.421-1.205c-0.541-1.706-1.591-3.167-3.034-4.226c-1.217-0.891-2.643-1.437-4.134-1.581V0.822C12.337,0.369,11.969,0,11.515,0H0.822C0.369,0,0,0.369,0,0.822v15.195c0,0.453,0.369,0.822,0.822,0.822h0.795c0.032,0.818,0.363,1.588,0.941,2.181C3.176,19.652,3.994,20,4.862,20c0.869,0,1.687-0.348,2.303-0.979c0.578-0.593,0.91-1.362,0.941-2.181h3.974c0.031,0.818,0.363,1.588,0.941,2.181C13.638,19.652,14.456,20,15.324,20s1.687-0.348,2.303-0.979c0.578-0.593,0.91-1.362,0.941-2.181h0.608c0.454,0,0.823-0.369,0.823-0.822V9.783C20,9.654,19.927,9.436,19.926,9.433z M15.324,18.355c-0.826,0-1.529-0.682-1.597-1.516h3.193C16.854,17.674,16.15,18.355,15.324,18.355z M4.862,18.355c-0.826,0-1.529-0.682-1.596-1.516h3.193C6.392,17.674,5.688,18.355,4.862,18.355z M10.692,1.645v13.55H1.645V1.645H10.692z M12.337,8.961V4.075c2.617,0.316,4.804,2.128,5.604,4.66c0.002,0.008,0.005,0.016,0.008,0.023l0.07,0.202H12.337z M18.354,10.606v4.588h-6.018v-4.588H18.354z"
                    />
                  </svg>
                  {{ $_('components/navbar.menu_items.waste_company_resources', 'Zdroje') }}
                </nuxt-link>
              </li>

              <li v-if="$project.isUserAllowed('r', 'WasteEvidenceHeader')" class="nav-item">
                <nuxt-link
                  dusk="navbar-waste-evidence-headers"
                  class="nav-link"
                  to="/waste-evidence-headers"
                  :class="{
                    active: $route.name && $route.name?.startsWith('waste-evidence-headers'),
                  }"
                >
                  <svg class="icon nav-link__icon" viewBox="0 0 20 20" width="20px" height="20px" aria-hidden="true">
                    <path
                      d="M12.793,9.414H11.23v6.914h1.563V9.414z M8.77,9.414H7.207v6.914H8.77V9.414z M16.465,2.849h-2.773V2.344C13.691,1.051,12.64,0,11.348,0H8.613C7.321,0,6.27,1.051,6.27,2.344v0.505H3.535c-1.292,0-2.344,1.051-2.344,2.344V7.93h1.563v9.727C2.754,18.949,3.806,20,5.098,20h9.805c1.292,0,2.344-1.051,2.344-2.344V7.93h1.563V5.193C18.809,3.9,17.757,2.849,16.465,2.849z M7.832,2.344c0-0.431,0.351-0.781,0.781-0.781h2.734c0.431,0,0.781,0.351,0.781,0.781v0.505H7.832V2.344z M15.684,17.656c0,0.431-0.351,0.781-0.781,0.781H5.098c-0.431,0-0.781-0.351-0.781-0.781V7.93h11.367V17.656zM17.246,6.367H2.754V5.193c0-0.431,0.351-0.781,0.781-0.781h12.93c0.431,0,0.781,0.35,0.781,0.781V6.367z"
                    />
                  </svg>
                  {{ $_('components/navbar.menu_items.waste_evidence_headers', 'Nádoby') }}
                </nuxt-link>
              </li>

              <li v-if="$project.isUserAllowed('r', 'WasteCompanyCustomer')" class="nav-item">
                <nuxt-link
                  dusk="navbar-waste-customers"
                  class="nav-link"
                  :class="{ active: $route.name?.startsWith('waste-customers') }"
                  to="/waste-customers"
                >
                  <svg class="icon nav-link__icon" viewBox="0 0 20 20" width="20px" height="20px" aria-hidden="true">
                    <path
                      d="M12.337,11.954v1.916H20v-1.916H12.337z M15.862,5.862C15.862,2.63,13.232,0,10,0S4.138,2.63,4.138,5.862c0,1.806,0.836,3.505,2.252,4.615c-1.298,0.501-2.461,1.26-3.461,2.26C1.04,14.626,0,17.138,0,19.809V20h1.916v-0.191c0-4.458,3.627-8.084,8.084-8.084C13.232,11.725,15.862,9.095,15.862,5.862z M10,9.809c-2.176,0-3.946-1.771-3.946-3.946c0-2.177,1.77-3.946,3.946-3.946c2.176,0,3.946,1.77,3.946,3.946C13.946,8.038,12.176,9.809,10,9.809z M12.337,20H20v-1.916h-7.663V20z M12.337,16.935H20v-1.916h-7.663V16.935z"
                    />
                  </svg>
                  {{ $_('components/navbar.menu_items.waste_customers', 'Zákazníci') }}
                </nuxt-link>
              </li>

              <li
                v-if="$project.isUserAllowed('r', 'WasteCompany') || $project.isUserAllowed('s', 'WasteCompany')"
                class="nav-item"
              >
                <nuxt-link
                  v-if="!$project.isUserAllowed('r', 'WasteCompany') && getBranch(getWasteCompanyBranchId)"
                  class="nav-link"
                  :to="`/waste-companies/${getBranch(getWasteCompanyBranchId).wasteCompanyId}`"
                >
                  <svg class="icon nav-link__icon" viewBox="0 0 20 20" width="20px" height="20px" aria-hidden="true">
                    <path
                      d="M12.337,11.954v1.916H20v-1.916H12.337z M15.862,5.862C15.862,2.63,13.232,0,10,0S4.138,2.63,4.138,5.862c0,1.806,0.836,3.505,2.252,4.615c-1.298,0.501-2.461,1.26-3.461,2.26C1.04,14.626,0,17.138,0,19.809V20h1.916v-0.191c0-4.458,3.627-8.084,8.084-8.084C13.232,11.725,15.862,9.095,15.862,5.862z M10,9.809c-2.176,0-3.946-1.771-3.946-3.946c0-2.177,1.77-3.946,3.946-3.946c2.176,0,3.946,1.77,3.946,3.946C13.946,8.038,12.176,9.809,10,9.809z M12.337,20H20v-1.916h-7.663V20z M12.337,16.935H20v-1.916h-7.663V16.935z"
                    />
                  </svg>
                  {{ $_('components/navbar.menu_items.waste_company_administration', 'Správa') }}
                </nuxt-link>
                <nuxt-link
                  v-else
                  dusk="navbar-waste-companies"
                  class="nav-link"
                  :class="{ active: isWasteCompaniesActive }"
                  to="/waste-companies"
                >
                  <svg class="icon nav-link__icon" viewBox="0 0 20 20" width="20px" height="20px" aria-hidden="true">
                    <path
                      d="M12.337,11.954v1.916H20v-1.916H12.337z M15.862,5.862C15.862,2.63,13.232,0,10,0S4.138,2.63,4.138,5.862c0,1.806,0.836,3.505,2.252,4.615c-1.298,0.501-2.461,1.26-3.461,2.26C1.04,14.626,0,17.138,0,19.809V20h1.916v-0.191c0-4.458,3.627-8.084,8.084-8.084C13.232,11.725,15.862,9.095,15.862,5.862z M10,9.809c-2.176,0-3.946-1.771-3.946-3.946c0-2.177,1.77-3.946,3.946-3.946c2.176,0,3.946,1.77,3.946,3.946C13.946,8.038,12.176,9.809,10,9.809z M12.337,20H20v-1.916h-7.663V20z M12.337,16.935H20v-1.916h-7.663V16.935z"
                    />
                  </svg>
                  {{ $_('components/navbar.menu_items.waste_companies', 'Technické služby') }}
                </nuxt-link>
              </li>
            </ul>

            <div
              class="user-logged d-flex cursor-pointer align-items-center"
              data-bs-toggle="modal"
              data-bs-target="#chooseWasteCompanyBranch"
            >
              <img v-if="companyLogo" :src="companyLogo" alt="logo" class="header-logo" />
              <button
                dusk="modal-opener-button"
                type="button"
                class="btn btn-user shadow-none ms-2 modal-button"
                data-bs-toggle="modal"
                data-bs-target="#chooseWasteCompanyBranch"
              >
                <strong>{{ authUserName() }}</strong>
                <br />
                <span v-if="$store.getters['global/getWasteCompanyBranchId']" class="text-gray">
                  {{ companyBranchName }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>

    <!-- Sub menu -->
    <div v-if="$route.name && $route.name?.startsWith('waste-company-resources')" class="page-submenu">
      <div class="container-xxxl">
        <ul class="nav nav-submenu">
          <li v-if="$project.isUserAllowed('r', 'Car')" class="nav-item">
            <NuxtLink
              class="nav-link nav-link--submenu"
              :class="{
                active: $route.name?.startsWith('waste-company-resources-cars'),
              }"
              to="/waste-company-resources/cars"
            >
              {{ $_('components/navbar.menu_items.waste_company_resources.cars', 'Auta') }}
            </NuxtLink>
          </li>
          <li v-if="$project.isUserAllowed('r', 'Worker')" class="nav-item">
            <NuxtLink
              dusk="submenu-waste-company-resources-workers"
              class="nav-link nav-link--submenu"
              :class="{
                active: $route.name?.startsWith('waste-company-resources-workers'),
              }"
              to="/waste-company-resources/workers"
            >
              {{ $_('components/navbar.menu_items.waste_company_resources.workers', 'Personál') }}
            </NuxtLink>
          </li>
          <li v-if="$project.isUserAllowed('r', 'Device')" class="nav-item">
            <NuxtLink
              dusk="submenu-waste-company-resources-devices"
              class="nav-link nav-link--submenu"
              :class="{
                active: $route.name?.startsWith('waste-company-resources-devices'),
              }"
              to="/waste-company-resources/devices"
            >
              {{ $_('components/navbar.menu_items.waste_company_resources.devices', 'Zařízení') }}
            </NuxtLink>
          </li>
          <li v-if="$project.isUserAllowed('r', 'Device')" class="nav-item">
            <NuxtLink
              dusk="submenu-waste-company-resources-devices"
              class="nav-link nav-link--submenu"
              :class="{
                active: $route.name?.startsWith('waste-company-resources-external-services'),
              }"
              to="/waste-company-resources/external-services"
            >
              {{ $_('components/navbar.menu_items.waste_company_resources.external_services', 'Externí služby') }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="$route.name && $route.name?.startsWith('waste-customers')" class="page-submenu">
      <div class="container-xxxl">
        <ul class="nav nav-submenu">
          <li class="nav-item">
            <NuxtLink
              class="nav-link nav-link--submenu"
              :class="{ active: !$route.name.includes('export') }"
              to="/waste-customers"
              exact
            >
              {{ $_('components/navbar.menu_items.waste_customers.list', 'Seznam') }}
            </NuxtLink>
          </li>
          <li class="nav-item">
            <NuxtLink
              dusk="submenu-waste-customers-export"
              class="nav-link nav-link--submenu"
              :class="{
                active: $route.name?.startsWith('waste-customers-export'),
              }"
              to="/waste-customers/export"
            >
              {{ $_('components/navbar.menu_items.waste_customers.export', 'Export') }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="showAdministrationSubmenu" class="page-submenu">
      <div class="container-xxxl">
        <ul class="nav nav-submenu">
          <li v-if="$project.isUserAllowed('r', 'WasteCompany')" class="nav-item">
            <NuxtLink
              class="nav-link nav-link--submenu"
              :class="{ active: $route.name?.startsWith('waste-companies') }"
              to="/waste-companies"
              exact
            >
              {{ $_('components/navbar.menu_items.waste_companies.list', 'Seznam') }}
            </NuxtLink>
          </li>
          <li v-if="$project.isUserAllowed('r', 'AllWasteCompanyUsers')" class="nav-item">
            <NuxtLink
              dusk="submenu-waste-companies-users"
              class="nav-link nav-link--submenu"
              :class="{
                active: $route.name?.startsWith('administration-users'),
              }"
              to="/administration/users"
            >
              {{ $_('components/navbar.menu_items.administration.users', 'Správa uživatelů') }}
            </NuxtLink>
          </li>
          <li v-if="$project.isUserAllowed('r', 'AllDevices')" class="nav-item">
            <NuxtLink
              dusk="navbar-waste-companies-devices"
              class="nav-link nav-link--submenu"
              :class="{
                active: $route.name?.startsWith('administration-devices'),
              }"
              to="/administration/devices"
            >
              {{ $_('components/navbar.menu_items.administration.devices', 'Správa zařízení') }}
            </NuxtLink>
          </li>
          <li v-if="$project.isUserAllowed('r', 'AllDevices')" class="nav-item">
            <NuxtLink
              dusk="navbar-waste-companies-devices"
              class="nav-link nav-link--submenu"
              :class="{
                active: $route.name?.startsWith('administration-external-services'),
              }"
              to="/administration/external-services"
            >
              {{ $_('components/navbar.menu_items.administration.external_services', 'Externí služby') }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="$route.name && $route.name?.startsWith('waste-collection-routes')" class="page-submenu">
      <div class="container-xxxl">
        <ul class="nav nav-submenu">
          <li class="nav-item">
            <NuxtLink
              class="nav-link nav-link--submenu"
              to="/waste-collection-routes/regular"
              :class="{ active: $route.name.includes('regular') }"
              exact
            >
              {{ $_('components/navbar.menu_items.waste_collection_routes.regular', 'Trasy') }}
            </NuxtLink>
          </li>
          <li class="nav-item">
            <NuxtLink
              dusk="submenu-waste-collection-routes-exceptional"
              class="nav-link nav-link--submenu"
              to="/waste-collection-routes/exceptional"
              :class="{ active: $route.name?.includes('exceptional') }"
              exact
            >
              {{ $_('components/navbar.menu_items.waste_collection_routes.exceptional', 'Mimořádné svozy') }}
            </NuxtLink>
          </li>
          <li class="nav-item">
            <NuxtLink
              dusk="submenu-waste-collection-calendar"
              class="nav-link nav-link--submenu"
              to="/waste-collection-routes/calendar"
              :class="{ active: $route.name?.includes('calendar') }"
              exact
            >
              {{ $_('components/navbar.menu_items.waste_collection_routes.calendar', 'Kalendář') }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>

    <!-- Modal -->
    <div
      id="chooseWasteCompanyBranch"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="wasteCompanyBranchModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header pb-0">
            <h2 id="wasteCompanyBranchModalLabel" class="modal-title">
              {{ authUserName(true) }}
            </h2>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div class="modal-body pb-1">
            <div class="form-group mb-4">
              <label for="formNewUserRole" class="form-label">
                {{ $_('components/navbar.modal.waste_company_selection', 'Výběr firmy') }}
              </label>
              <search-select
                id="formNewUserRole"
                v-model="wasteCompanyBranchId"
                :config="config.waste_company_branch"
                :options="getWasteCompanyBranchOptions"
                :transform="transformWasteCompanyBranchOption"
                :aria-label="$_('components/navbar.modal.waste_company_selection', 'Výběr firmy')"
                class="px-0"
              >
                <template #option="option">
                  <img
                    v-if="option.logoUrl"
                    :src="option.logoUrl"
                    :alt="option.label"
                    class="waste-company-logo pe-2"
                  />
                  {{ option.label }}
                </template>
                <template #selected-option="option">
                  <img
                    v-if="option.logoUrl"
                    :src="option.logoUrl"
                    :alt="option.label"
                    class="waste-company-logo pe-2"
                  />
                  {{ option.label }}
                </template>
              </search-select>
            </div>
            <button
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-primary btn-lg w-100 mb-4"
              @click="chooseWasteCompanyBranch()"
            >
              {{ $_('components/navbar.modal.buttons.select_waste_company', 'Vybrat firmu') }}
            </button>
            <div class="row">
              <div class="col">
                <button
                  data-bs-dismiss="modal"
                  type="button"
                  class="btn btn-outline-primary btn-lg w-100 mb-4"
                  @click="redirectToUserProfile()"
                >
                  {{ $_('components/navbar.modal.buttons.user_profile', 'Profil uživatele') }}
                </button>
              </div>
              <div class="col">
                <button
                  dusk="logout-button"
                  data-bs-dismiss="modal"
                  type="button"
                  class="btn btn-outline-primary btn-lg w-100 mb-4"
                  @click="logout()"
                >
                  {{ $_('components/navbar.modal.buttons.logout', 'Odhlásit') }}
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer py-0">
            <div class="text-muted w-100 text-end version">
              {{ `${$_('components/navbar.modal.version', 'verze')} ${version}` }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-button {
  border-color: #ccc !important;
}

.modal-button:hover {
  border-color: transparent !important;
}

img.waste-company-logo {
  max-height: 10px;
}

img.header-logo {
  max-width: 50px;
  max-height: 50px;
}
</style>
