<script>
import { mapGetters } from 'vuex'
import Navbar from '~/components/Navbar'
import Alert from '~/components/Alert'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min'

export default defineNuxtComponent({
  components: { Alert, Navbar },
  data() {
    return {
      version: useRuntimeConfig().public.APP_VERSION,
    }
  },
  computed: {
    ...mapGetters({
      getErrorMessage: 'getErrorMessage',
      getWarningMessages: 'getWarningMessages',
      getSuccessMessage: 'getSuccessMessage',
      getAll: 'validation/getAll',
      getAllTranslated: 'validation/getAllTranslated',
      getWasteCompanyBranchId: 'global/getWasteCompanyBranchId',
    }),
    getGeneralErrors() {
      const generalErrors = {}
      Object.keys(this.getAll)
        .filter(errorKey => !this.getAllTranslated.includes(errorKey))
        .forEach(error => {
          generalErrors[error] = this.getAll[error]
        })
      return generalErrors
    },
  },
  watch: {
    $route() {
      // remove stuck tooltips
      let tooltips = document.querySelectorAll('div.tooltip.show')
      while (tooltips.length > 0) {
        tooltips[0].parentNode.removeChild(tooltips[0])
        tooltips = document.querySelectorAll('div.tooltip.show')
      }
    },
    getWasteCompanyBranchId() {
      // Remove all values from localStorage whose keys include 'filter' or 'Filter on company branch change
      Object.keys(localStorage).forEach(key => {
        if (key.includes('filter') || key.includes('Filter')) {
          localStorage.removeItem(key)
        }
      })
    },
  },
  mounted() {
    this.injectJiraWidget()
    this.$global.registerSwUpdate(this)
    // Initialize tooltips
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },
  methods: {
    injectJiraWidget() {
      function jiraHelpdesk(callback) {
        let jhdScript = document.createElement('script')
        jhdScript.type = 'text/javascript'
        jhdScript.setAttribute('data-jsd-embedded', null)
        jhdScript.setAttribute('data-key', useRuntimeConfig().public.JSD_KEY)
        jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com')
        jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js'
        if (jhdScript.readyState) {
          // old IE support
          jhdScript.onreadystatechange = function () {
            if (jhdScript.readyState === 'loaded' || jhdScript.readyState === 'complete') {
              jhdScript.onreadystatechange = null
              callback()
            }
          }
        } else {
          //modern browsers
          jhdScript.onload = function () {
            callback()
          }
        }
        document.getElementsByTagName('head')[0].appendChild(jhdScript)
      }

      jiraHelpdesk(function () {
        let DomContentLoadedEvent = new Event('DOMContentLoaded', {
          bubbles: true,
          cancelable: true,
        })
        window.document.dispatchEvent(DomContentLoadedEvent)
      })
    },
  },
})
</script>
<template>
  <NuxtPwaManifest />
  <NuxtLoadingIndicator color="#666" :height="2" />
  <div :key="getWasteCompanyBranchId">
    <navbar></navbar>

    <main class="o-main" role="main">
      <div class="container-xxxl">
        <alert v-if="getErrorMessage" variant="danger" class="sticky-top">{{ getErrorMessage }}</alert>
        <alert v-if="getSuccessMessage" variant="success">
          {{ getSuccessMessage }}
        </alert>

        <alert v-for="(warningMessage, key) in getWarningMessages" :key="key" variant="warning">
          {{ warningMessage }}
        </alert>

        <slot />
        <alert v-if="getErrorMessage" variant="danger" class="mt-4">{{ getErrorMessage }}</alert>
        <alert v-if="Object.keys(getGeneralErrors).length" variant="danger" class="mt-4">
          <div
            id="generalErrorButton"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#generalError"
            aria-expanded="false"
            aria-controls="generalError"
            class="w-100 collapsed d-flex"
          >
            {{ $_('layouts/default.general_error', 'Obecná chyba') }}
            <img
              class="icon btn__icon ms-auto me-0 px-0"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAbklEQVRIie2POwqAMBAFR/CuFpqgeA4LO++qGJsNBFExH8FiBwJLeLzZBUX5PVVkdpR5AlzpRWYpdcASudzr8l2ek79sybncAk0gybrkqtxTRDLclHtsIOlTBB2wAuYhY4ANaFMEAHWhjKIoX3IAelQfpGwwph4AAAAASUVORK5CYII="
            />
          </div>
          <div id="generalError" class="accordion-collapse collapse">
            <span v-for="(error, name) in getGeneralErrors" :key="name">
              {{ `${name}: ${error}` }}
            </span>
          </div>
        </alert>
      </div>
    </main>
    <footer class="o-footer bg-white">
      <div class="container px-5">
        <div class="row align-items-center gy-2 gx-lg-5">
          <div class="col-md-auto">
            <a :href="$_('layouts/default.jrk_logo_link', 'https://www.jrkdigital.eu')" target="_blank">
              <img src="~/assets/images/jrk-logo.svg" alt="JRK" />
            </a>
          </div>
          <div class="col-md-auto border-md-start">
            <a :href="$_('layouts/default.knowledge_base_link', 'https://wiki.jrkdigital.eu')" target="_blank">
              {{ $_('layouts/default.knowledge_base', 'Znalostní databáze') }}
            </a>
          </div>
          <div class="col-md-auto border-md-start">
            <a :href="`mailto:${$_('layouts/default.support_email', 'support@jrkdigital.eu')}`" target="_blank">
              {{ $_('layouts/default.support_email', 'support@jrkdigital.eu') }}
            </a>
          </div>
          <div class="col-24 d-lg-none"></div>
          <div class="col-auto ms-lg-auto">
            <ul class="nav nav-social">
              <li class="nav-item">
                <a
                  class="nav-link link-youtube"
                  :href="$_('layouts/default.youtube_link', 'https://www.youtube.com/user/JRKcompany')"
                  :title="$_('layouts/default.youtube', 'Youtube')"
                  target="_blank"
                >
                  {{ $_('layouts/default.youtube', 'Youtube') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link link-facebook"
                  :href="$_('layouts/default.facebook_link', 'https://www.facebook.com/meneodpadu')"
                  :title="$_('layouts/default.facebook', 'Facebook')"
                  target="_blank"
                >
                  {{ $_('layouts/default.facebook', 'Facebook') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link link-linkedin"
                  :href="$_('layouts/default.linkedin_link', 'https://www.linkedin.com/company/jrk-ceska-republika')"
                  :title="$_('layouts/default.linkedin', 'LinkedIn')"
                  target="_blank"
                >
                  {{ $_('layouts/default.linkedin', 'LinkedIn') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link link-instagram"
                  :href="$_('layouts/default.instagram_link', 'https://www.instagram.com/jrk_ceska_republika/')"
                  :title="$_('layouts/default.instagram', 'Instagram')"
                  target="_blank"
                >
                  {{ $_('layouts/default.instagram', 'Instagram') }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-auto border-start">{{ `${$_('layouts/default.version', 'Verze')} ${version}` }}</div>
        </div>
      </div>
    </footer>
  </div>
</template>

<style scoped>
#generalErrorButton > img {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}
#generalErrorButton:not(.collapsed) > img {
  transform: rotate(360deg);
}
</style>
